<template>
  <Form 
    @submit="savePassword"
    :validation-schema="schema"
    class="relative flex justify-center items-center"
    >
    <div class="grid grid-cols-1 gap-6 mt-4 text-gray-900">
      <div class="relative text-left">
        <label for="current_password" class="sm:text-xl" > Contraseña actual: </label>
        <Field
          type="password"
          name="current_password"
          class="form-input w-full mt-2 rounded-md sm:text-xl border-gray-500"
          v-model="current_password"
        />
        <ErrorMessage name="current_password" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="relative text-left">
        <label for="password" class="sm:text-xl"> Contraseña nueva: </label>
        <Field
          type="password"
          name="password"
          class="form-input w-full mt-2 rounded-md sm:text-xl border-gray-500"
          v-model="password"
          placeholder="•••••••"
        />
        <ErrorMessage name="password" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="relative text-left">
        <label for="password_confirm" class="sm:text-xl"> Confirma la contraseña: </label>
        <Field
          type="password"
          name="password_confirm"
          class="form-input w-full mt-2 rounded-md sm:text-xl border-gray-500"
          v-model="password_confirmation"
          placeholder="•••••••"
        />
        <ErrorMessage name="password_confirm" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="flex justify-center mt-4">
        <BaseBtn
          type="submit"
          text="Cambiar contraseña"
          btnClass="px-6 py-4 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none mt-8"
        />
      </div>
    </div>
  </Form>
</template>
<script>
import AuthService from "@/services/AuthService"
import BaseBtn from "@/components/BaseBtn"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string, ref } from "yup"

export default {
  name: "UpdatePasswordForm",
  setup() {
    const schema = object({
      current_password: string().required("La contraseña actual es obligatoria."),
      password: string().required("Debes ingresar una nueva contraseña.").min(8, "Debe tener al menos 8 caracteres."),
      password_confirm: string().required("Debes completar este campo.").oneOf([ref('password'), null], "Las contraseñas deben coincidir.")
    })

    return {
      schema
    }
  },
  data(){
    return {
      current_password: "",
      password: "",
      password_confirmation: ""
    }
  },
  methods: {
    savePassword() {
      let payload = {
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation
      }

      AuthService.updatePassword(payload)
        .then(() => {
          this.emitter.emit("notification-set", {
            type: 'success',
            title: "Contraseña modificada",
            text: "Se ha realizado tu cambio de contraseña. ¡No la olvides!",
            time: 0,
            group: 'notifications'
          })
        })
    }
  },
  components: {
    BaseBtn,
    Field,
    Form,
    ErrorMessage
  }
}
</script>