<template>
    <div class="bg-blue-50 px-6">
        <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
            <div class="px-12 py-6">
                <h1 
                class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
                <faIcon
                icon="key"
                type="fas"
                class="text-green-500 text-3xl"
                >
                </faIcon>
                Cambio de contraseña
                </h1>
                <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Cambia la contraseña que usas para iniciar sesión.</p>
            </div>
        </div>
        <div class="bg-gray-200 mt-4">
            <div class="p-6 bg-white rounded-md shadow-md">
                <UpdatePasswordForm/>
            </div>
        </div>
    </div>
</template>
<script>
import UpdatePasswordForm from "@/components/usuarios/UpdatePasswordForm"
export default {
    name: "UpdatePasswordView",
    components: {
        UpdatePasswordForm
    }
}
</script>